.img-back {
  background-size: cover;
  background-position: center;
  width: screen;
  margin: 20px 20px;
  border: solid #fff;
  border-radius: 30px;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.main-text {
  font-family: 'HelveticaNeue-BlackCond', sans-serif;
  font-size: 125px;
  margin-top: 20px;
  color: #fff;
  text-align: center;
}

.item-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.item-lista {
  font-family: 'AcuminProExtraCondBold', sans-serif;
  color: #fff;
  font-size: 35px;
  font-weight: 500;
  animation: pulse 1.6s infinite;
}

.item-lista:hover {
  background-color: #e5c998;
  font-size: 35px;
  transition: background-color 0.3s, font-size 0.3s;
  border: solid #fff;
  border-radius: 25px;
  padding: 3px 15px 3px 15px;
}

.img-back .linha-divisoria {
  width: 97.5%;
  border: none;
  border-top: 5px solid white;
  margin: -15px auto 50px;
}

.texto-borda {
  border: 3px solid white;
  border-radius: 25px 25px 25px 25px;
  font-size: 72px;
  font-family: 'AcuminProExtraCondBold', sans-serif;
  padding: 25px;
  margin: 20px;
  text-align: justify;
  color: #fff;
  line-height: 1.1; 
  letter-spacing: -0.5px;
}

.react-typed {
  font-family: 'AcuminProExtraCondBold', sans-serif;
  color: #e5c998;
  font-size: 50px;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 768px) {
  .img-back {
    margin: 20px 10px;
  }

  .overlay-img {
    height: 100%;
    width: auto;
  }

  .main-text {
    font-size: 65px;
  }

  .react-typed {
    font-size: 55px;
    padding: 30vh 20px 0 20px;
    position: absolute;
  }

  .item-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 400px 20px 0 20px;
  }

  .item-lista {
    font-size: 35px;
  }

  .texto-borda {
    font-size: 40px;
  }
}

@media (max-width: 480px) {
  .img-back {
    margin: 20px 5px;
  }

  .main-text {
    font-size: 50px;
  }

  .react-typed {
    font-size: 35px;
    padding: 45vh 20px 0 20px;
    position: absolute;
  }

  .item-container {
    margin-top: 10px;
  }

  .overlay-img {
    height: 100%;
    width: auto;
  }

  .item-lista {
    font-size: 25px;
  }

  .texto-borda {
    font-size: 22px;
  }

  .img-back .buttonpro {
    font-size: 12px;
    padding: 10px 50px;
  }
}