.borda-motores {
  border: 3px solid white;
  border-radius: 25px;
  font-size: 30px;
  margin: 20px;
  color: #fff;
  padding-top: 20px;
  z-index: 1;
}

.motores-logo {
  margin: 0 auto;
}

.motores-estrategia-img {
  width: auto;
  height: 500px;
  margin: 0 auto 100px auto;
}

.conteudo-comunicacao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
}

.texto-principal-comunicacao {
  width: 82%;
  margin: 0 auto;
  font-size: 187.5px;
  font-family: 'AcuminProExtraCondBlack', sans-serif;
  line-height: 1;
  color: #e94935;
  text-shadow: 5px 5px 1px #00367e;
  /* Horizontal, vertical, blur, color */
  letter-spacing: -6px;
}


.texto-apoio-comunicacao {
  width: 80%;
  margin: -15px auto 5px auto;
  font-size: 29.5px;
  font-family: 'Ash', sans-serif;
  line-height: 1;
  color: #e5c998;
  font-style: italic;
}

.conteudo-esquerda {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.conteudo-direita {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.linha-divisoria-laranja {
  border: none;
  border-top: 3px solid #e94935;
  width: 80%;
  margin: 0 auto 20px auto;
}

.texto-header {
  width: 80%;
  font-family: 'AcuminProExtraCondBlack', sans-serif;
  text-align: left;
  color: #fff;
  margin: 10px auto 10px auto;
  font-size: 45px;
  line-height: 1;
}

.conteudo-esquerda .icones-comunicacao {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 25px auto;
  text-align: left;
}

.texto-p {
  width: 80%;
  font-size: 30px;
  font-family: 'HelveticaNeueLight', sans-serif;
  text-align: left;
  color: #fff;
  margin: 10px auto 35px auto;
}

.capsula-img {
  width: auto;
  height: 400px;
  margin: 0 auto 20px auto;
}

.busspacesite-img {
  width: auto;
  height: 1000px;
  margin: 0 auto 0 auto;
}

.carro-espacial-img {
  width: auto;
  height: 400px;
  margin: 0 auto 20px auto;
}

.capsula-sombra-img {
  width: auto;
  height: 450px;
  margin: 0 auto 20px auto;
}

.orbita-img {
  margin: 0 20px 0 20px;
  width: auto;
  height: 100px;
}

.globo-1-img {
  margin: 0 20px 0 20px;
  width: auto;
  height: 100px;
}

.globo-2-img {
  margin: 0 20px 0 20px;
  width: auto;
  height: 100px;
}

.linha-divisoria-branca-top,
.linha-divisoria-branca-bottom {
  border: none;
  border-top: 3px solid #fff;
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.linha-com-capsula {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 50px 0;
}

.capsula-laranja-img {
  width: auto;
  height: 100px;
  position: absolute;
}

.conteudo-gestao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 100px auto;
}

.conteudo-gestao-esquerda {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.conteudo-gestao-direita {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.texto-principal-gestao {
  width: 82%;
  margin: 0 auto;
  font-size: 355px;
  font-family: 'AcuminProExtraCondBlack', sans-serif;
  line-height: 1;
  color: #e94935;
  text-shadow: 5px 5px 1px #00367e;
  /* Horizontal, vertical, blur, color */
  letter-spacing: -6px;
}


.texto-apoio-gestao {
  width: 80%;
  margin: -30px auto 5px auto;
  font-size: 30px;
  font-family: 'Ash', sans-serif;
  line-height: 1;
  color: #e5c998;
  font-style: italic;
}

.texto-header-gestao {
  width: 80%;
  margin: 10px auto 50px auto;
  font-size: 45px;
  font-family: 'AcuminProExtraCondBlack', sans-serif;
  line-height: 1;
}

.texto-p-gestao {
  width: 80%;
  margin: 0 auto;
  font-size: 30px;
  font-family: 'HelveticaNeueLight', sans-serif;
}

.titulo-gestao-img {
  width: auto;
  height: 365px;
  margin: 0 auto;
}

.texto-principal-marketing {
  width: 82%;
  margin: 0 auto;
  font-size: 232px;
  font-family: 'AcuminProExtraCondBlack', sans-serif;
  line-height: 1;
  color: #e94935;
  text-shadow: 5px 5px 1px #00367e;
  /* Horizontal, vertical, blur, color */
  letter-spacing: -6px;
}


.texto-apoio-marketing {
  width: 80%;
  margin: -15px auto 5px auto;
  font-size: 26.5px;
  font-family: 'Ash', sans-serif;
  line-height: 1;
  color: #e5c998;
  font-style: italic;
}

.conteudo-marketing-esquerda {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.conteudo-marketing-direita {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.conteudo-marketing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 150px;
}

.texto-header-marketing {
  width: 80%;
  text-align: left;
  margin: 10px auto 50px auto;
  font-size: 45px;
  font-family: 'AcuminProExtraCondBlack', sans-serif;
  line-height: 1;
}

.texto-p-marketing {
  width: 80%;
  text-align: left;
  margin: 0 auto 40px auto;
  font-size: 30px;
  font-family: 'HelveticaNeueLight', sans-serif;
}

@media (max-width: 768px) {
  .borda-motores {
    margin: 10px;
  }

  .texto-header,
  .texto-header-gestao,
  .texto-header-marketing {
    font-size: 35px;
    text-align: center;
    width: 85%;
    margin: 50px auto;
  }

  .texto-p,
  .texto-p-gestao,
  .texto-p-marketing {
    font-size: 25px;
    text-align: center;
    width: 82%;
    margin: 50px auto;
  }

  .motores-estrategia-img {
    height: 350px;
    width: auto;
  }

  .conteudo-comunicacao,
  .conteudo-gestao,
  .conteudo-marketing {
    flex-direction: column;
    align-items: center;
  }

  .conteudo-esquerda,
  .conteudo-direita,
  .conteudo-gestao-esquerda,
  .conteudo-gestao-direita,
  .conteudo-marketing-esquerda,
  .conteudo-marketing-direita {
    width: 100%;
    margin: 0 auto 0 auto;
  }

  .orbita-img,
  .globo-1-img,
  .globo-2-img {
    height: 75px;
  }

  .carro-espacial-img,
  .capsula-img,
  .capsula-sombra-img,
  .busspacesite-img {
    height: 300px;
  }

  .linha-divisoria-laranja,
  .linha-divisoria-branca-top,
  .linha-divisoria-branca-bottom {
    width: 85%;
  }

  .linha-divisoria-branca-top,
  .linha-divisoria-branca-bottom {
    width: 95%;
  }

  .texto-principal-gestao {
    width: 88%;
    font-size: 225px;
    text-align: center;
    margin: 0 auto -10px auto;
  }

  .texto-apoio-gestao {
    width: 90%;
    font-size: 12.5px;
    text-align: center;
    margin: 0 auto 5px auto;
  }

  .texto-principal-comunicacao {
    width: 88%;
    font-size: 125px;
    text-align: center;
    margin: 0 auto -10px auto;
  }

  .texto-apoio-comunicacao {
    width: 90%;
    font-size: 19px;
    text-align: center;
    margin: 0 auto 5px auto;
  }

  .texto-principal-marketing {
    width: 88%;
    font-size: 150px;
    text-align: center;
    margin: 0 auto -10px auto;
  }

  .texto-apoio-marketing {
    width: 84%;
    font-size: 16px;
    text-align: center;
    margin: 0 auto 5px auto;
  }
}

@media (max-width: 480px) {

  .motores-logo {
    padding: auto 0;
    margin-top: 50px;
  }

  .texto-header,
  .texto-header-gestao,
  .texto-header-marketing {
    font-size: 25px;
    text-align: justify;
    margin: 0 auto 10px;
  }

  .conteudo-direita .icones-comunicacao {
    margin: 10px auto;
  }

  .texto-p,
  .texto-p-gestao,
  .texto-p-marketing {
    font-size: 20px;
    text-align: justify;
    margin: 20px auto;
  }

  .titulo-comunicao-img {
    width: auto;
    height: 110px;
    margin-top: 75px;
  }

  .titulo-gestao-img {
    width: auto;
    height: 180px;
    margin-top: -25px;
  }

  .titulo-marketing-img {
    width: auto;
    height: 140px;
    margin-top: 30px;
  }

  .motores-estrategia-img {
    height: 200px;
    width: auto;
  }

  .orbita-img,
  .globo-1-img,
  .globo-2-img {
    height: 50px;
  }

  .carro-espacial-img,
  .capsula-img,
  .capsula-sombra-img,
  .busspacesite-img {
    height: 350px;
    width: auto;
  }

  .linha-divisoria-laranja,
  .linha-divisoria-branca-top,
  .linha-divisoria-branca-bottom {
    width: 85%;
  }

  .texto-principal-gestao {
    width: 88%;
    font-size: 155px;
    text-align: center;
    margin: 0 auto -10px auto;
  }

  .texto-apoio-gestao {
    width: 84%;
    font-size: 12px;
    text-align: center;
    margin: 0 auto 5px auto;
  }

  .texto-principal-comunicacao {
    width: 88%;
    font-size: 85px;
    text-align: center;
    margin: 0 auto -10px auto;
  }

  .texto-apoio-comunicacao {
    width: 84%;
    font-size: 12px;
    text-align: center;
    margin: 0 auto 5px auto;
  }

  .texto-principal-marketing {
    width: 88%;
    font-size: 100px;
    text-align: center;
    margin: 0 auto -10px auto;
  }

  .texto-apoio-marketing {
    width: 84%;
    font-size: 10px;
    text-align: center;
    margin: 0 auto 5px auto;
  }
}