.launcher-background {
    background-size: cover;
    background-position: center;
    width: calc(100vw - 40px); /* Subtraindo as margens laterais */
    height: 88vh; /* Altura fixa para manter proporção */
    margin: 5px 20px;
    border: solid #e5c998;
    border-radius: 30px;
    z-index: 1;
    animation: fadeIn 1s ease-in-out;
    overflow: hidden;
    box-sizing: border-box; 
    position: relative;
}

.fullscreen-bg,
.fullscreen-mobile-bg {
    object-fit: cover;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    animation: fadeIn 1s ease-in-out;
}

@media (max-width: 768px) {
    .launcher-background {
        width: calc(100vw - 20px); /* Subtraindo as margens laterais */
        height: 85vh;
        margin: 1px 10px;
    }
}

@media (max-width: 480px) {
    .launcher-background {
        width: calc(100vw - 10px); /* Subtraindo as margens laterais */
        height: 80vh;
        margin: 1px 5px;
    }
}

.launcher-background:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    border: 1px solid #e5c998; /* Alterado para borda sólida */
    pointer-events: none;
    box-sizing: border-box;
}