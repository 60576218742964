.logo-partners {
    display: flex;
    justify-content: space-around; /* Distribui as imagens com espaçamento igual entre elas */
    align-items: center; /* Alinha verticalmente ao centro */
    flex-wrap: wrap; /* Permite que as imagens "quebrem" linha em resoluções menores */
    margin: 65px auto;
  }
  
  .logo-partners img {
    width: 100px; /* Define uma largura consistente para todas as imagens */
    height: auto; /* Mantém a proporção da imagem */
    margin: 10px; /* Adiciona margem para espaçamento entre as imagens */
  }
  
  @media (max-width: 768px) {
    .logo-partners img {
      width: 80px; /* Reduz a largura das imagens para tablets */
    }
  }
  
  @media (max-width: 480px) {
    .logo-partners img {
      width: 80px; /* Reduz ainda mais a largura das imagens para smartphones */
    }
  }
  