.whatsapp-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #25D366;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: pulse 1.6s infinite;
}

.whatsapp-button img {
  width: 60px;
  height: 60px;  
  border-radius: 50%;
}
