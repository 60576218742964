.man-jump {
  height: auto;
  margin: 0 auto;
}

.borda-motores {
  border: 3px solid white;
  border-radius: 25px;
  font-size: 30px;
  margin: 20px;
  color: #fff;
  z-index: 1;
}

.prospectus-amarelo-img {
  width: auto;
  height: 75px;
  margin: -75px auto 50px auto;
}

.wear-the-future-text {
  margin: 40px 350px 10px 350px;
  text-align: center;
}

.flutuante-img {
  height: auto;
  width: 150px;
  position: relative;
  bottom: 50px;
  left: 30px;
  z-index: 1000;
  display: initial;
  transform: translateY(85px);
}


.pnbn-tira-img {
  width: auto;
  height: screen;
  margin: 0 auto;
  padding: 75px 0;
}

.borda-motores .buttonpro {
  font-size: 20px;
}

@media (max-width: 768px) {
  .borda-motores {
    margin: 10px;
  }

  .wear-the-future-text {
    margin: 60px 40px 60px 40px;
    text-align: center;
  }

  .man-jump {
    width: 71vh;
    height: auto;
    margin: 20px auto 30px auto;
  }

  .prospectus-amarelo-img {
    width: auto;
    height: 75px;
    margin: 0 auto 50px auto;
  }

  .flutuante-img {
    height: auto;
    width: 20%;
    position: relative;
    bottom: 50px;
    left: 50%;
    z-index: 1000;
    transform: translate(-350px, 50px);
  }
}

@media (max-width: 480px) {
  .borda-motores {
    margin: 5px;
  }

  .wear-the-future-text {
    margin: 60px 40px 60px 40px;
    text-align: justify;
    line-height: 1.1;
    letter-spacing: -0.5px;
    font-size: 20px;
  }

  .man-jump {
    width: 50vh;
    height: auto;
    margin: 20px auto 30px auto;
  }

  .prospectus-amarelo-img {
    width: auto;
    height: 65px;
  }

  .flutuante-img {
    height: auto;
    width: 20%;
    position: relative;
    bottom: 50px;
    left: 50%;
    z-index: 1000;
    transform: translate(-150px, 50px);
  }

  .borda-motores .buttonpro {
    font-size: 12px;
    padding: 10px 75px;
  }
}