.qrcode-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ocupa toda a altura da viewport */
    width: 100vw; /* Ocupa toda a largura da viewport */
}

.qrcode-content {
    text-align: center;
}

.main-text {
    font-family: 'HelveticaNeue-BlackCond', sans-serif;
    font-size: 70px;
    margin-bottom: 20px; /* Adiciona um espaço entre o texto e o QR code */
}

.footer {
    background-color: #e3522c;
    border: 3px solid #e5c998;
    width: 100%;  
    position: fixed;
    bottom: 0;
}

.footer .direitos-reservados {
    border: none;
    font-size: 14px;
    font-family: 'HelveticaNeueLight', sans-serif;
}