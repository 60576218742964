.computador-background {
    width: 100%;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
}

.img-computador-fundo {
    height: 75%;
    width: 50%;
    margin: 0 auto;
}

.historia-sucesso {
    width: auto;
    height: 125px;
    align-self: center;
    margin: 0 auto;
    padding-top: 30px;
    transform: translateY(-115px);
}

.nosso-compromisso {
    width: auto;
    height: 110px;
    align-self: center;
    margin: 0 auto;
    padding-top: 30px;
    transform: translateY(85px);
}

@media (max-width: 768px) {
    .img-computador-fundo {
        height: 40%;
        width: auto;
    }

    .historia-sucesso {
        height: 95px;
        width: auto;
        transform: translateY(-130px);
    }

    .nosso-compromisso {
        height: 85px;
        width: auto;
        transform: translateY(110px);
    }
}

@media (max-width: 480px) {
    .img-computador-fundo {
        height: 30%;
        width: 80%;
    }

    .historia-sucesso {
        height: 42px;
        width: auto;
        padding-top: 10px;
        transform: translateY(-60px);
    }

    .nosso-compromisso {
        height: 40px;
        width: auto;
        padding-top: 10px;
        transform: translateY(50px);
    }
}
