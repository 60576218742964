.prospectus-bege {
    width: auto;
    height: 27px;
    margin: 100px auto;
}

.styled-box {
    background-color: rgba(229, 201, 152, 0);
    border: solid 3px #e5c998;
    padding: 20px;
    margin: 0 450px;
    color: #e5c998;
    font-weight: 800;
    font-size: 40px;
    box-shadow: 3px 3px 3px #e3522c;
    font-family: 'AcuminProExtraCondBold', sans-serif;
    text-align: center;
}

.astronauta-subtitulo {
    font-size: 60px;
    margin: 150px;
    padding: 50px;
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-family: 'AcuminProExtraCondBold', sans-serif;
}

.astronauta-paragrafo {
    font-size: 35px;
    margin: 150px;
    padding: 50px;
    color: #fff;
    text-align: center;
    font-weight: 200;
}

.img-star {
    margin: 0 auto 175px auto;
    width: auto;
    height: 125px;
}

.img-sucesso {
    margin: 50px auto 0 auto;
    padding-bottom: 40px;
    width: 80%;
    height: auto;
}

.linha-divisoria-branca {
    position: relative;
    width: 80%;
    margin: 0 auto;
    border-top: 3px solid #fff;
}

.linha-vertical {
    position: absolute;
    bottom: 100%;
    width: 5px;
    height: 30px;
    background-color: #fff;
}

.linha-vertical:nth-child(1) { left: 0%; }
.linha-vertical:nth-child(2) { left: 12.50%; }
.linha-vertical:nth-child(3) { left: 25%; }
.linha-vertical:nth-child(4) { left: 37.50%; }
.linha-vertical:nth-child(5) { left: 50%; }
.linha-vertical:nth-child(6) { left: 62.50%; }
.linha-vertical:nth-child(7) { left: 75%; }
.linha-vertical:nth-child(8) { left: 87.50%; }
.linha-vertical:nth-child(9) { left: 100%; }

.astrolua {
    margin: 50px auto -20px auto;
}

@media (max-width: 768px) {
    .prospectus-bege {
        width: auto;
        height: 20px;
        margin: 100px auto;
    }

    .astronauta-subtitulo {
        font-size: 38px;
        width: 85%;
        margin: 50px auto;
    }

    .styled-box {
        margin: 0 175px;
        font-size: 30px;
        text-align: center;
    }

    .astronauta-paragrafo {
      font-size: 30px;
      width: 85%;
      margin: 50px auto;
    }

    .img-sucesso {
        width: 625px;
        height: auto;
        margin: 0 auto;
    }

    .astrolua {
        margin: 50px auto -12px auto;
    }
  }
  
  @media (max-width: 480px) {
    .astronauta-subtitulo {
        font-size: 30px;
        padding: 0 5px;
        margin: 50px auto;
        text-align: justify;
        line-height: 1.1;
        letter-spacing: -0.5px;
    }

    .astronauta-paragrafo {
        font-size: 25px;
        padding: 0 5px;
        margin: 50px auto;
        text-align: justify;
        line-height: 1.1;
        letter-spacing: -0.5px;
    }

    .prospectus-bege {
        width: auto;
        height: 10px;
        margin: 45px auto;
    }

    .styled-box {
        margin: 5px 50px;
        font-size: 16px;
        text-align: center;
        white-space: nowrap;
    }

    .img-star {
        width: 50px;
        height: auto;
        margin: 40px auto;
    }

    .img-sucesso {
        width: 302px;
        height: auto;
        margin: 0 auto;
        padding-bottom: 20px;
    }

    .linha-divisoria-branca {
        border-top: 2px solid #fff;
    }
    
    .linha-vertical {
        width: 3px;
        height: 20px;
    }

    .astrolua {
        margin: 50px auto -5px auto;
    }
  }