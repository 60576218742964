.footer-web {
  background-color: #e3522c;
  border: 3px solid #e5c998;
}

.grade {
  display: flex;
  flex-direction: column;
}

.primeira-grade {
  display: flex;
  align-items: center;
}

.primeira-grade-icones-esquerda {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.primeira-grade-icones-direita {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.primeira-grade-icones-esquerda img,
.primeira-grade-icones-direita img {
  display: flex;
  gap: 10px;
  height: auto;
  width: 60px;
}

.central-text {
  font-family: 'HelveticaNeue-BlackCond', sans-serif;
  text-align: center;
  font-size: 80px;
  font-weight: 400;
  color: #e5c998;
  width: 60%;
}

.primeira-grade .linha-divisoria-laranja-vertical {
  border: 3px solid #e5c998;
  height: 125px;
  margin: 0;
}

.segunda-grade {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.segunda-grade .btn-footer,
.segunda-grade .btn-footer::after {
margin: 0 50px;
 padding: 16px 20px;
 font-size: 18px;
 background: linear-gradient(45deg, transparent 5%, #055ec4 5%);
 border: 0;
 color: #fff;
 letter-spacing: 3px;
 line-height: 1;
 box-shadow: 6px 0px 0px #e5c998;
 outline: transparent;
 position: relative;
}

.segunda-grade .btn-footer::after {
 --slice-0: inset(50% 50% 50% 50%);
 --slice-1: inset(80% -6px 0 0);
 --slice-2: inset(50% -6px 30% 0);
 --slice-3: inset(10% -6px 85% 0);
 --slice-4: inset(40% -6px 43% 0);
 --slice-5: inset(80% -6px 5% 0);
 content: "HOVER ME";
 display: block;
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background: linear-gradient(45deg, transparent 3%, #00e6f6 3%, #00e6f6 5%, #e3522c 5%);
 text-shadow: -3px -3px 0px #f8f005, 3px 3px 0px #00e6f6;
 clip-path: var(--slice-0);
}

.segunda-grade .btn-footer:hover::after {
 animation: 1s glitch;
 animation-timing-function: steps(2, end);
}

@keyframes glitch {
 0% {
  clip-path: var(--slice-1);
  transform: translate(-20px, -10px);
 }

 10% {
  clip-path: var(--slice-3);
  transform: translate(10px, 10px);
 }

 20% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 10px);
 }

 30% {
  clip-path: var(--slice-3);
  transform: translate(0px, 5px);
 }

 40% {
  clip-path: var(--slice-2);
  transform: translate(-5px, 0px);
 }

 50% {
  clip-path: var(--slice-3);
  transform: translate(5px, 0px);
 }

 60% {
  clip-path: var(--slice-4);
  transform: translate(5px, 10px);
 }

 70% {
  clip-path: var(--slice-2);
  transform: translate(-10px, 10px);
 }

 80% {
  clip-path: var(--slice-5);
  transform: translate(20px, -10px);
 }

 90% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 0px);
 }

 100% {
  clip-path: var(--slice-1);
  transform: translate(0);
 }
}


.terceira-grade {
  display: grid;
  grid-template-columns: 49.75% 0.25% 49.75%;
}

.terceira-grade .left-image {
  height: auto;
  width: screen;
}

.terceira-grade .right-image {
  height: auto;
  width: screen;
  margin: auto auto;
}

.terceira-grade .linha-divisoria-laranja-vertical {
  border: solid;
  border: 3px solid #e5c998;
  height: 100%;
  margin: 0;
}

.linha-divisoria-laranja-horizontal {
  border-top: 5px solid #e5c998;
  width: 100%;
  margin: 0;
}

.direitos-reservados {
  text-align: center;
  font-size: 25px;
  font-weight: 300;
  color: #e5c998;
  width: 100%;
  margin: 10px 0;
}

.quarta-grade {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.quarta-grade .cnpj {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  color: #e5c998;
  width: 50%;
  margin: 5px auto;
}

.quarta-grade .email {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  color: #e5c998;
  width: 50%;
  margin: 5px auto;
}

@media (max-width: 768px) {
  .primeira-grade {
    display: flex;
    align-items: center;
    height: 95px;
  }

  .primeira-grade .linha-divisoria-laranja-vertical {
    border: 3px solid #e5c998;
    height: 95px;
    margin: 0;
  }

  .primeira-grade-icones-esquerda {
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    width: 25%;
  }
  
  .primeira-grade-icones-direita {
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    width: 25%;
  }

  .central-text {
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    color: #fff;
    width: 50%;
    color: #e5c998;
  }

  .segunda-grade {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .segunda-grade .btn-footer {
    margin: 0 50px;
    padding: 16px 20px;
  }

  .primeira-grade-icones-esquerda img,
  .primeira-grade-icones-direita img {
    display: flex;
    gap: 10px;
    height: auto;
    width: 35px;
  }

  .cnpj {
    font-weight: 400;
  }

  .quarta-grade .cnpj {
    font-weight: 400;
  }

  .quarta-grade .email {
    font-weight: 400;
  }
}

@media (max-width: 480px) {
  .primeira-grade {
    display: flex;
    align-items: center;
    height: 95px;
  }

  .linha-divisoria-laranja-horizontal {
    border-top: 1px solid #e5c998;
    width: 100%;
    margin: 0;
  }

  .primeira-grade .linha-divisoria-laranja-vertical {
    border: 1px solid #e5c998;
    height: 95px;
    margin: 0;
  }

  .primeira-grade-icones-esquerda {
    display: flex;
    gap: 1px;
    align-items: center;
    justify-content: center;
    width: 35%;
  }
  
  .primeira-grade-icones-direita {
    display: flex;
    gap: 1px;
    align-items: center;
    justify-content: center;
    width: 35%;
  }

  .central-text {
    text-align: center;
    font-size: 21px;
    font-weight: 400;
    color: #fff;
    width: 30%;
    color: #e5c998;
  }

  .primeira-grade-icones-esquerda img,
  .primeira-grade-icones-direita img {
    display: flex;
    gap: 10px;
    height: auto;
    width: 30px;
  }

  .segunda-grade .btn-footer {
    margin: 0 auto;
    padding: 16px 20px;
  }

  .terceira-grade {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .grade .terceira-grade .linha-divisoria-laranja-vertical {
    display: none;
  }

  .grade .terceira-grade .left-image,
  .grade .terceira-grade .right-image {
    width: 49%;
    margin: 0 auto;
  }

  .cnpj {
    font-size: 14px;
    font-weight: 400;
  }

  .direitos-reservados {
    text-align: center;
    font-size: 20px;
    margin: 20px auto;
  }

  .quarta-grade {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .quarta-grade .cnpj {
    font-weight: 400;
    font-size: 5px;
    margin: 0 auto;
  }

  .quarta-grade .email {
    font-weight: 400;
    font-size: 5px;
    margin: 0 auto;
  }
}