.linha-divisoria {
    border-radius: 20px;
    border-bottom: 2px solid white;
    width: calc(100% - 300px); /* Ajuste conforme necessário */
    position: relative;
    bottom: -16px;
  }

  @media (max-width: 480px) {
    .linha-divisoria {
        display: none;
    }
  }