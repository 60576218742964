@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HelveticaNeue-BlackCond';
  src: url('./assets/fonts/HelveticaNeue-BlackCond.otf') format('opentype');
}

@font-face {
  font-family: 'AcuminProExtraCondBold';
  src: url('./assets/fonts/AcuminProExtraCondBold.otf') format('opentype');
}

@font-face {
  font-family: 'AcuminProExtraCondBlack';
  src: url('./assets/fonts/AcuminProExtraCondBlack.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaNeueLight';
  src: url('./assets/fonts/HelveticaNeueLight.ttf') format('opentype');
}

@font-face {
  font-family: 'Ash';
  src: url('./assets/fonts/Ash.ttf') format('opentype');
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

body {
  background-color: #055ec4;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Para navegadores WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#055ec4, #e5c998); /* Degrade da barra de rolagem */
  border-radius: 6px;
}

/* Para navegadores Mozilla (Firefox) */
::-moz-scrollbar {
  width: 10px;
}

::-moz-scrollbar-thumb {
  background: linear-gradient(#055ec4, #e5c998); /* Degrade da barra de rolagem */
  border-radius: 6px;
}

.buttonpro {
  --btn-default-bg: #e3522c;
  --btn-padding: 20px 100px;
  --btn-hover-bg: rgb(255, 255, 255);
  --btn-transition: 0.3s;
  --btn-letter-spacing: 0.1rem;
  --btn-animation-duration: 1.2s;
  --btn-shadow-color: rgb(255, 255, 255);
  --btn-shadow: 0 2px 10px 0 var(--btn-shadow-color);
  --hover-btn-color: #7241ff;
  --default-btn-color: #fff;
  --font-size: 20px;
  --font-weight: 600;
  font-family: 'AcuminProExtraCondBold', sans-serif;
  border-radius: 6em;
  margin: 40px auto 40px auto;
  animation: pulse 1.6s infinite;
}

.buttonpro {
  box-sizing: border-box;
  padding: var(--btn-padding);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--default-btn-color);
  font: var(--font-weight) var(--font-size) var(--font-family);
  background: var(--btn-default-bg);
  cursor: pointer;
  transition: var(--btn-transition);
  overflow: hidden;
  box-shadow: var(--btn-shadow);
  border-radius: 6em;
  border: 2px solid #fff;
}

.buttonpro span {
  letter-spacing: var(--btn-letter-spacing);
  transition: var(--btn-transition);
  box-sizing: border-box;
  position: relative;
  background: inherit;
}

.buttonpro span::before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  background: inherit;
}

.buttonpro:focus {
  scale: 1.09;
}

.buttonpro:hover,
.buttonpro:focus {
  background: var(--btn-default-bg);
  box-shadow: 0px 0px 10px 0px rgba(119, 68, 255, 0.7);
  border: 2px solid #E5C998;
}

.buttonpro:hover span,
.buttonpro:focus span {
  color: #E5C998;
}

.buttonpro:hover span::before,
.buttonpro:focus span::before {
  animation: chitchat linear both var(--btn-animation-duration);
}

@keyframes chitchat {
  0% {
    content: "#";
  }

  5% {
    content: ".";
  }

  10% {
    content: "^{";
  }

  15% {
    content: "-!";
  }

  20% {
    content: "#$_";
  }

  25% {
    content: "№:0";
  }

  30% {
    content: "#{+.";
  }

  35% {
    content: "@}-?";
  }

  40% {
    content: "?{4@%";
  }

  45% {
    content: "=.,^!";
  }

  50% {
    content: "?2@%";
  }

  55% {
    content: "\;1}]";
  }

  60% {
    content: "?{%:%";
    right: 0;
  }

  65% {
    content: "|{f[4";
    right: 0;
  }

  70% {
    content: "{4%0%";
    right: 0;
  }

  75% {
    content: "'1_0<";
    right: 0;
  }

  80% {
    content: "{0%";
    right: 0;
  }

  85% {
    content: "]>'";
    right: 0;
  }

  90% {
    content: "4";
    right: 0;
  }

  95% {
    content: "2";
    right: 0;
  }

  100% {
    content: "";
    right: 0;
  }
}